















































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
    apiOrderDetail
} from "@/api/card/physical_vouchers";
@Component({})
export default class voucherCardDetail extends Vue {
    id = this.$route.query.id;
    detail = {
        order_sn: '',
        physical_vouchers_coupon_back: {
            type: 0,
            printing_method: 0,
            coupon_password_email: '',
            keys_phone: '',
            email_topic: '',
            email_content: '',
            material: 0,
            size: 0,
            effect: 0,
            monovalent: 0,
            amount: 0,
            total_cost: 0,
            other_notes: '',
            is_urgent: 0,
            is_expiration_date: 0,
            contact: '',
            contact_number: '',
            mailing_address: '',
            shipping_methods: 0,
            source_files: '',
            preview_files: '',
        },
        product_type: 0,
        company_name: '',
        customer_id: '',
        product_profile_name: '',
        scope_of_application: 0,
        benchmark_price: 0,
        discount_cap: 0,
        sale_unit_price: 0,
        sale_number: 0,
        sale_discount: 0,
        included_amount: 0,
        included_number: 0,
        validity_period_start: '',
        validity_period_end: '',
        activation_method: 0,
        activation_time: '',
		link_type_name:'',
		link_type:"",
		link_id:"",
		link_id_name:"",
        is_electronic_signature: 0,
        audit_status: 0
    };
    tableData2: any[] = [];
    tableData: any[] = [];

    async created() {
        await this.getDetail();
    }

    getDetail() {
        apiOrderDetail({
            id: this.id,
        }).then((res:any) => {
            this.detail = res;

            res.product_profiles_auditing_log_list.forEach(
                (element: any, index: number) => {
                    if (element.end_time === "") {
                        res.product_profiles_auditing_log_list[
                            index
                        ].delayedTime = "";
                    } else {
                        let start_time =
                            new Date(element.start_time).getTime() / 1000;
                        let end_time =
                            new Date(element.end_time).getTime() / 1000;
                        res.product_profiles_auditing_log_list[
                            index
                        ].delayedTime =
                            Math.ceil((end_time - start_time) / 60) + "分钟";
                    }
                }
            );

            this.tableData = res.product_profiles_auditing_log_list;
            this.tableData2 = res.physical_vouchers_coupon_order_batch_list;
        });
    }

}
